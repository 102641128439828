import { SID } from '@securitize/domain';
import countries from 'i18n-iso-countries';
import { documents as sidDocuments } from '../../../../store/modules/securitize-id/options';

const { categories: { documentTypeIndividual, documentsTypeEntity, face } } = sidDocuments;

const disableNewEntityTypes = process.env.VUE_APP_DISABLE_NEW_ENTITY_TYPES === 'true';
const availableEntityTypes = [
  SID.InvestorEntityTypes.REVOCABLE_TRUST,
  SID.InvestorEntityTypes.IRREVOCABLE_TRUST,
  SID.InvestorEntityTypes.LIMITED_GENERAL_PARTNERSHIP,
  SID.InvestorEntityTypes.LLC,
  SID.InvestorEntityTypes.CORPORATION,
  SID.InvestorEntityTypes.FUND,
  ...(disableNewEntityTypes ? [] : [
    SID.InvestorEntityTypes.FOR_PROFIT_FOUNDATION,
    SID.InvestorEntityTypes.NON_PROFIT_FOUNDATION,
  ]),
  SID.InvestorEntityTypes.OTHER,
];

const getCountriesList = () => {
  const countriesObject = countries.getNames('en');
  return Object.keys(countriesObject)
    .map(countryCode => ({
      value: countryCode,
      text: countriesObject[countryCode],
    }));
};
export const legalSignerOptionsList = {
  genderTypesList: [
    {
      value: 'male',
      text: 'Male',
    },
    {
      value: 'female',
      text: 'Female',
    },
  ],
  signerType: [
    {
      value: 'individual',
      text: 'Individual',
    },
    {
      value: 'entity',
      text: 'Entity',
    },
  ],
  entityTypesList: availableEntityTypes.map(value => ({
    value,
    i18nKey: `securitizeId.entityType["${value}"]`,
  })),
};
export const optionsList = {
  genderTypesList: [
    {
      value: 'male',
      text: 'Male',
    },
    {
      value: 'female',
      text: 'Female',
    },
  ],
  investorsTypesList: [
    {
      value: 'individual',
      text: 'Individual',
    },
    {
      value: 'entity',
      text: 'Entity',
    },
  ],
  entityTypesList: availableEntityTypes.map(value => ({
    value,
    i18nKey: `securitizeId.entityType["${value}"]`,
  })),
};

export const countriesList = getCountriesList();

export const tableFields = [
  {
    key: 'name', label: 'Name / Entity', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '200px', maxWidth: '200px' }, tdClass: 'align-middle text-break',
  },
  {
    key: 'countryCode', label: 'Country', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '90px' }, tdClass: 'align-middle',
  },
  {
    key: 'investorType', label: 'Type', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '50px' }, tdClass: 'align-middle',
  },
  {
    key: 'verificationStatus', label: 'Identity checks', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '110px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'suitabilityStatus', label: 'US Suitability', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '90px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'suitabilityEuStatus', label: 'EU Suitability', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '90px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'accreditationStatus', label: 'Accreditation', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '90px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'accountStatus', label: 'US Account', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '90px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'accountEuStatus', label: 'EU Account', sortable: false, thClass: 'text-nowrap text-center', thStyle: { minWidth: '90px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'createDate', label: 'Created', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '120px' }, tdClass: 'align-middle',
  },
  {
    key: 'assignedAttorneyOperatorId', label: 'Assignee', sortable: false, thClass: 'text-nowrap', thStyle: { minWidth: '140px' }, tdClass: 'align-middle text-center',
  },
  {
    key: 'actions', label: '', sortable: false, tdClass: 'align-middle',
  },
];

export const kycStatuses = {
  none: 'badge-grey',
  processing: 'badge-orange',
  verified: 'badge-green',
  'updates-required': 'badge-orange',
  'manual-review': 'badge-yellow',
  rejected: 'badge-red',
  'not-verified': 'badge-red',
  expired: 'badge-orange',
};

export const documentsVerificationStatuses = {
  pending: 'badge-orange',
  verified: 'badge-green',
  'not-verified': 'badge-red',
};

export const filterLists = {
  investorType: [
    { text: 'All', value: null },
    { text: 'Individual', value: 'individual' },
    { text: 'Entity', value: 'entity' },
  ],
  kycStatus: [
    { text: 'All', value: null },
    { text: 'None', value: 'none' },
    { text: 'Processing', value: 'processing' },
    { text: 'Update required', value: 'updates-required' },
    { text: 'Verified', value: 'verified' },
    { text: 'Manual Review', value: 'manual-review' },
    { text: 'Enhance Requested', value: 'enhance-requested' },
    { text: 'Enhance Verified', value: 'enhance-verified' },
    { text: 'Rejected', value: 'rejected' },
    { text: 'Verification Expired', value: 'expired' },
  ],
};

export const kyc = {
  statuses: [
    { value: 'none', i18nKey: 'securitizeId.kyc.option.status.none' },
    { value: 'processing', i18nKey: 'securitizeId.kyc.option.status.processing' },
    { value: 'updates-required', i18nKey: 'securitizeId.kyc.option.status.updateRequired' },
    { value: 'verified', i18nKey: 'securitizeId.kyc.option.status.verified' },
    { value: 'manual-review', i18nKey: 'securitizeId.kyc.option.status.manualReview' },
    { value: 'rejected', i18nKey: 'securitizeId.kyc.option.status.rejected' },
    { value: 'expired', i18nKey: 'securitizeId.kyc.option.status.expired' },
    { value: 'verified-documents-expired', i18nKey: 'securitizeId.kyc.option.status.verifiedDocumentsExpired' },
    { value: 'enhance-requested', i18nKey: 'securitizeId.kyc.option.status.enhanceRequested' },
    { value: 'enhance-verified', i18nKey: 'securitizeId.kyc.option.status.enhanceVerified' },
    { value: 'verified-expired', i18nKey: 'securitizeId.kyc.option.status.verifiedExpired' },
    { value: 'rejected-blocked', i18nKey: 'securitizeId.kyc.option.status.rejectedBlocked' },
  ],
};

export const suitabilityLogTableFields = [
  { key: 'createdAt', label: 'Date', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'status', label: 'Suitability Status', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'operator', label: 'Operator', sortable: true, tdClass: 'align-middle w-25' },
];

export const suitability = {
  statuses: [
    { value: 'none', i18nKey: 'brokerDealer.investorDetails.verificationTab.suitabilityStatuses.none' },
    { value: 'processing', i18nKey: 'brokerDealer.investorDetails.verificationTab.suitabilityStatuses.processing' },
    { value: 'verified', i18nKey: 'brokerDealer.investorDetails.verificationTab.suitabilityStatuses.verified' },
    { value: 'not-approved', i18nKey: 'brokerDealer.investorDetails.verificationTab.suitabilityStatuses.notApproved' },
  ],
};

export const suitabilityStatuses = {
  none: 'badge-grey',
  processing: 'badge-orange',
  verified: 'badge-green',
  'not-approved': 'badge-red',
};

export const accreditation = {
  statuses: [
    { value: 'none', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.none' },
    { value: 'processing', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.processing' },
    { value: 'verified', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.verified' },
    { value: 'rejected', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.rejected' },
    { value: 'not-accredited', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.notAccredited' },
    { value: 'pending', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.pending' },
    { value: 'updates-required', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.updatesRequired' },
    { value: 'expired', i18nKey: 'brokerDealer.investorDetails.verificationTab.accreditationStatuses.expired' },
  ],
};

export const accreditationStatuses = {
  none: 'badge-grey',
  processing: 'badge-orange',
  pending: 'badge-orange',
  'updates-required': 'badge-orange',
  verified: 'badge-green',
  rejected: 'badge-red',
  expired: 'badge-red',
  'not-accredited': 'badge-yellow',
};

export const accountLogTableFields = [
  { key: 'createDate', label: 'Date', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'status', label: 'Account Status', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'operator', label: 'Operator', sortable: true, tdClass: 'align-middle w-25' },
];

export const account = {
  statuses: [
    { value: 'none', i18nKey: 'brokerDealer.investorDetails.verificationTab.accountStatuses.none' },
    { value: 'approved', i18nKey: 'brokerDealer.investorDetails.verificationTab.accountStatuses.approved' },
    { value: 'rejected', i18nKey: 'brokerDealer.investorDetails.verificationTab.accountStatuses.rejected' },
  ],
};

export const brokerDealerVerificationLogTableFields = [
  { key: 'createdAt', label: 'Date', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'status', label: 'Broker dealer verification status', sortable: true, tdClass: 'align-middle w-25' },
  { key: 'operator', label: 'Operator', sortable: true, tdClass: 'align-middle w-25' },
];

export const brokerDealerVerification = {
  statuses: [
    { value: 'none', i18nKey: 'brokerDealer.investorDetails.verificationTab.brokerDealerAmlStatuses.none' },
    { value: 'processing', i18nKey: 'brokerDealer.investorDetails.verificationTab.brokerDealerAmlStatuses.processing' },
    { value: 'verified', i18nKey: 'brokerDealer.investorDetails.verificationTab.brokerDealerAmlStatuses.verified' },
    { value: 'not-approved', i18nKey: 'brokerDealer.investorDetails.verificationTab.brokerDealerAmlStatuses.notApproved' },
  ],
};

export const accountStatuses = {
  none: 'badge-grey',
  approved: 'badge-green',
  rejected: 'badge-red',
};

export const cashAccountStatuses = {
  none: 'badge-grey',
  'opened-with-sid': 'badge-green',
  'opened-with-bd': 'badge-green',
};

const documents = {
  categories: {
    documentTypeIndividual,
    documentsTypeEntity,
    face,
  },
  getFields: isConfidential => [
    {
      key: 'thumbnail',
      label: 'Preview',
    },
    !isConfidential && {
      key: 'docCategory',
      label: 'Document Category',
      tdClass: 'align-middle',
    },
    !isConfidential && {
      key: 'docType',
      label: 'Document Type',
      tdClass: 'align-middle',
    },
    {
      key: 'createDate',
      label: 'Creation Date',
      tdClass: 'align-middle',
    },
    !isConfidential && {
      key: 'status',
      label: 'status',
      tdClass: 'align-middle',
    },
    {
      key: 'actions',
      label: ' ',
      tdClass: 'text-nowrap d-inline float-right',
    },
  ],
};

export default documents;
